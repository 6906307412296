import { useEffect, useRef, useState } from "react";
import "../assets/css/packaging.css";
import video_thumbnail from "../assets/images/packaging_images/banner_video.png";
import whitePepperBanner from "../assets/images/packaging_images/fleur-colonie-banner.jpg";
import chees_slices_1 from "../assets/images/packaging_images/chees_slices_1.jpg";
import chees_slices_2 from "../assets/images/packaging_images/chees_slices_2.jpg";

import lavazza_image from "../assets/images/packaging_images/lavazza_image.jpg";
import lavazza_video_thumbnail from "../assets/images/packaging_images/lavazza_video_thumbnail.png";
import fleur_colonie_1_thumbnail from "../assets/images/packaging_images/fleur_colonie_1_thumbnail.png";
import fleur_colonie_2_thumbnail from "../assets/images/packaging_images/fleur_colonie_2_thumbnail.png";
import festivel_treats_thumbnail from "../assets/images/packaging_images/festivel_treats_thumbnail.png";

import grb_bis_thumbnail from "../assets/images/packaging_images/grb_bis_thumbnail.png";
import grb_sweets_thumbnail from "../assets/images/packaging_images/grb_sweets_thumbnail.png";
import coffy_bite_thumbnail from "../assets/images/packaging_images/coffy_bite_thumbnail.png";
import banjaras_shampoo from "../assets/images/packaging_images/banjaras_shampoo.jpg";
import banjaras_cream from "../assets/images/packaging_images/banjaras_cream.jpg";
import ampersand_microsite_banjaaras_sunscreen_thumbnail from "../assets/images/packaging_images/ampersand_microsite_banjaaras_sunscreen_thumbnail.png";
import hutImage2 from "../assets/images/hut_image2.webp";

import thirumala_milk_pack_thumbnail from "../assets/images/packaging_images/Thirumala-Milk-Pack_thumbnail.png";
import { useStore } from "../store";
import { NavbarContainer } from "../components/NavbarContainer";
import { ContactUsPopup } from "../components/ContactUsPopup";
import { UserForm } from "../components/UserForm";
import { VideoPlayer } from "../components/VideoPlayer";
import { Preloader } from "../components/Preloader";

export const Packaging = () => {

    const showOverlayVisibilityStatus = useStore((store) => store.showOverlay);
    const toggleContactFormVisibilityCB = useStore((store) => store.toggleContactFormVisibility);
    const closeAllPopupsCB = useStore((store) => store.closeAllPopups);
    const varstore = useRef(undefined);
    const [canPlay, setCanPlay] = useState(false);
    const [isBannerVideoLoaded, setIsBannerVideoLoaded] = useState(false);
    const [isPageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        document.title = "Ampersand | Packaging";

        const handlePageLoad = () => {
            setPageLoaded(true);
        };

        if (document.readyState === "complete") {
            handlePageLoad();
        } else
            window.addEventListener('load', handlePageLoad);

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    varstore.videoRef.volume = 0.25;
                    // if (window.innerWidth > 768) {
                    varstore.videoRef.play()
                        .catch((err) => {
                            console.log('err', err);
                        });
                    // }
                } else {
                    varstore.videoRef && varstore.videoRef.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.5, // Trigger when 50% of the element is visible
        });

        if (varstore.videoRef) {
            varstore.intervalId = setTimeout(() => {
                observer.observe(varstore.videoRef);
                varstore.videoRef.muted = true;
            }, 1000);

        }

        window.addEventListener(('keydown'), (e) => {
            if (e.key === 'Escape') {
                closeAllPopupsCB();
            }
        })
    }, []);

    return (<div className="packaging-container">
        <Preloader isBannerVideoLoaded={isBannerVideoLoaded} ispageLoaded={isPageLoaded} />

        <NavbarContainer actionBtnText={'tell me more'} />

        <section className="packaging-video-container">
            {/* <VideoPlayer poster={video_thumbnail} source={'https://ampersand.in/videos/packaging/ampersand_microsite_twinnings.mp4'} controls={true} /> */}

            <video ref={elem => varstore.videoRef = elem}
                className="banner-video"
                width="100%"
                height="100%"
                loop
                controls
                autoPlay={canPlay}
                muted={varstore?.videoRef?.muted}
                webkit-playsinline={'true'} playsInline
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
                onPlaying={() => {
                    // varstore.videoRef.muted = false;
                    // console.log("video playing");
                }}
                onCanPlayThrough={() => {
                    setCanPlay(true);
                    setIsBannerVideoLoaded(true);
                }}
            >
                <source src={'https://ampersand.in/videos/packaging/ampersand_microsite_twinnings.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </section>

        <h1 className="welcome-heading">We tell great stories through packaging</h1>

        <section className="text-content">
            welcome to a tapestry of visually stunning packaging designs, extraordinary stories in boxes, packs and bottles put together by an incredible design team that brings latest global styles and design trends to your products, combining  solid strategy with well researched consumer buying behaviour, category understanding and sound marketing approach.
        </section>

        <section className="packaging-white-pepper-banner single-layout">
            <img src={whitePepperBanner} alt="" />
        </section>

        <section className="packaging-custom-pick-product">
            <span>93%</span>
            <div>
                <span>customers pick a product</span>
                <span>if they love the packaging design</span>
            </div>
        </section>

        <section className="packaging-count-grid">
            <div>
                <p className="item-count">500+</p>
                <span>packaging <br />projects handled</span>
            </div>
            <div>
                <p className="item-count">12+</p>
                <span>senior designers <br />with expertise in the craft</span>
            </div>
            <div>
                <p className="item-count">80+</p>
                <span>brands collaborated with <br />regional/ national/ global levels</span>
            </div>
        </section>

        <section className="fleur-colonie-holder double-layout">
            <div className="overlay-block">
                <VideoPlayer
                    poster={fleur_colonie_2_thumbnail}
                    source={'https://ampersand.in/videos/packaging/fleur_colonie_2.mp4'}
                    loop={true}
                    muted={true}
                    controls={false}
                    autoPlay={true}
                />
                <div className="overlay"></div>
                {/* <span className="overlay-text">Fleur Colonie</span> */}
            </div>
            <div className="overlay-block">
                <VideoPlayer poster={fleur_colonie_1_thumbnail} source={'https://ampersand.in/videos/packaging/fleur_colonie_1.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
                <div className="overlay"></div>
                {/* <span className="overlay-text">Fleur Colonie</span> */}
            </div>
        </section>

        <section className="text-content">
            the whimsical  French girl, from the south of France, goes on an expedition through far away forests, runny rivers and mystical mountains, searching for exotic botanicals, colonies of rare flowers, fruits and foliage to make exotic beauty potions. that is Fleur Colonie, the newest brand in the Indian beauty place.
        </section>


        <section className="single-layout">
            <div className="overlay-block">
                <VideoPlayer poster={thirumala_milk_pack_thumbnail} source={'https://ampersand.in/videos/packaging/Thirumala-Milk-Pack.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
                <div className="overlay"></div>
                {/* <span className="overlay-text">Thirumala</span> */}
            </div>
        </section>

        <section className="text-content">
            the hard working Indian farmer, wakes up to the call of his cows every morning, and goes about his day caring for his animals and his  little piece of land with love and devotion. the design for this milk brand is inspired by the tranquil everyday mornings of  farmers in India, carefully capturing the beautiful mundane and its sanctity.
        </section>

        <section className="double-layout">
            <img src={chees_slices_1} alt="" />
            <img src={chees_slices_2} alt="" />
        </section>

        <section className="text-content">
            when the world no.1 dairy company launched its cheese in India, the story was to showcase mouthwatering cheese combos using a global design language
        </section>

        <section className="packaging-festivel-treats">
            <VideoPlayer poster={festivel_treats_thumbnail} source={'https://ampersand.in/videos/packaging/festivel_treats.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
        </section>

        <section className="double-layout">
            <VideoPlayer poster={lavazza_video_thumbnail} source={'https://ampersand.in/videos/packaging/lavazza_video.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
            <img src={lavazza_image} alt="" />
        </section>


        <section className="single-layout">
            <VideoPlayer poster={grb_sweets_thumbnail} source={'https://ampersand.in/videos/packaging/grb_sweets.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
        </section>

        <section className="single-layout">
            <VideoPlayer poster={coffy_bite_thumbnail} source={'https://ampersand.in/videos/packaging/coffy_bite.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
        </section>

        <section className="text-content">
            coffee or toffee? much loved by Indian kids across 4 decades, this smooth, melt in your mouth coffybite’s new version is fun, creamy and rich! the design is swirl of cream and coffee, taking you on visual whirl of wonder and excitement.
        </section>

        <section className="triple-layout">
            <img src={banjaras_shampoo} alt="" />
            <VideoPlayer poster={ampersand_microsite_banjaaras_sunscreen_thumbnail} source={'https://ampersand.in/videos/packaging/ampersand_microsite_banjaaras_sunscreen.mp4'} loop={true} muted={true} controls={false} autoPlay={true} />
            <img src={banjaras_cream} alt="" />
        </section>

        <section className="packaging-footer double-layout">
            <span className="text-content">
                if you are looking at launching a new product, rebranding or redesigning your current product- you have arrived a the right place. work with partners who bring freshness, intelligence and exceptional design aesthetics to your product. let’s co-create something wonderful for you!
            </span>
            <img src={hutImage2} alt="" />
        </section>

        <div className="call-to-action"
            onClick={() => {
                toggleContactFormVisibilityCB(true)
            }}>tell me more</div>

        <ContactUsPopup />
        <UserForm />

        <div className={`popup-outer ${showOverlayVisibilityStatus ? 'pop-in' : ''}`} onClick={closeAllPopupsCB}></div>
    </div>)
}